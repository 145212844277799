<template>
  <div class="content">
    <div class="title">
      完成验证开始免费体验
    </div>
    <div class="form-wrap">
      <el-form
        ref="ruleForm"
        :model="form"
        :rules="rules"
      >
        <el-form-item
          label=""
          prop="name"
        >
          <el-input
            v-model="form.name"
            placeholder="姓名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label=""
          prop="phone"
        >
          <el-input
            v-model="form.phone"
            placeholder="电话"
          ></el-input>
        </el-form-item>
        <el-form-item
          label=""
          prop="title"
        >
          <el-input
            v-model="form.title"
            placeholder="职务"
          ></el-input>
        </el-form-item>
        <el-form-item
          label=""
          prop="company_name"
        >
          <el-input
            v-model="form.company_name"
            placeholder="机构名称"
          ></el-input>
        </el-form-item>
        <div class="input-wrap">
          <el-form-item
            label=""
            class="city-item"
            prop="province"
          >
            <el-select
              v-model="form.province"
              placeholder="省"
              @change="provinceChange"
            >
              <el-option
                v-for="(item,index) in provinceList"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label=""
            class="city-item"
            prop="city"
          >
            <el-select
              v-model="form.city"
              placeholder="市"
              @change="cityChange"
            >
              <el-option
                v-for="(item,index) in cityList"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label=""
            class="city-item"
            prop="area"
          >
            <el-select
              v-model="form.area"
              placeholder="区/县"
              @change="areaChange"
            >
              <el-option
                v-for="(item,index) in areaList"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item
          label=""
          prop="need"
        >
          <el-input
            type="textarea"
            v-model="form.need"
            placeholder="当前需求"
            :autosize="autosize"
          ></el-input>
        </el-form-item>
        <div class="input-wrap">
          <el-form-item
            label=""
            prop="code"
          >
            <el-input
              v-model="form.code"
              placeholder="图形验证码"
              style="width: 2.6rem;"
            ></el-input>
          </el-form-item>
          <div @click="refreshCode">
            <s-identify :identifyCode="identifyCode"></s-identify>
          </div>
        </div>
        <div
          label=""
          class="input-wrap"
          style="justify-content: start;align-items:center;"
        >
          <el-checkbox v-model="isChecked">
          </el-checkbox>
          <div
            class="checkbox-text"
            @click="agree"
          >阅读并接受
            <span>《研盒用户协议》</span>
          </div>
        </div>
      </el-form>
    </div>
    <div
      class="submit-btn"
      @click="submit('ruleForm')"
    >
      立即提交
    </div>
    <div class="tips">
      <div
        class="tips-item"
        @click="handleChangeType"
      >
        已有帐号？<span>直接登录</span>
      </div>
    </div>
  </div>
</template>

<script>
import SIdentify from '@/components/identify'
import citylist from "@/utils/area.json";
export default {
  components: {
    SIdentify
  },

  data() {
    return {
      form: {},
      autosize: {
        minRows: 4
      },
      identifyCodes: "1234567890",
      identifyCode: "",
      code: "",
      provinceList: citylist,
      cityList: [],
      areaList: [],
      isChecked: true,
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号' }
        ],
        title: [
          { required: true, message: '请输入职务', trigger: 'blur' }
        ],
        province: [
          { required: true, message: '请选择省', trigger: 'blur' }
        ],
        city: [
          { required: true, message: '请输入市', trigger: 'blur' }
        ],
        area: [
          { required: true, message: '请输入区/县', trigger: 'blur' }
        ],
        need: [
          { required: true, message: '请输入需求', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
      }
    };
  },
  mounted() {

    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
  methods: {
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
    },
    provinceChange(e) {
      this.cityList = []
      this.form.city = ""
      this.areaList = []
      this.form.area = ""
      this.cityList = this.provinceList.find((v) => { return v.name == e }).children
    },
    cityChange(e) {
      this.areaList = []
      this.form.area = ""
      this.areaList = this.cityList.find((v) => { return v.name == e }).children
    },
    areaChange(e) {
      this.$forceUpdate()
      this.form.area = e
    },
    //提交
    submit(formName) {
      if (!this.isChecked) {
        this.$message.error('请阅读并接受《研盒用户协议》');
        this.identifyCode = "";
        this.makeCode(this.identifyCodes, 4);
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.code !== this.identifyCode) {
            this.$message.error('图形验证码不一致，请重新填写');
            this.identifyCode = "";
            this.makeCode(this.identifyCodes, 4);
            return
          }
          const data = Object.assign({}, {
            name: this.form.name,
            phone: this.form.phone,
            title: this.form.title,
            need: this.form.need,
            company_name: this.form.company_name,
            address: this.form.province + this.form.city + this.form.area
          })
          this.$refs[formName].resetFields();
          this.$emit('submit', data)
        } else {
          this.identifyCode = "";
          this.makeCode(this.identifyCodes, 4);
          return false;
        }
      });
    },
    handleChangeType() {
      this.$emit('change', 'login')
    },
    agree() {
      this.$emit('agree')
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  margin-top: 0.56rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 0.3rem;
  }
  .form-wrap {
    margin-top: 0.32rem;
    width: 440px;

    /deep/.el-input__inner {
      border-radius: 2px;
    }
    .input-wrap {
      display: flex;
      justify-content: space-between;
      /*   align-items: center; */

      .city-item {
        width: 130px;
      }
      .checkbox-text {
        font-size: 0.14rem;
        margin-left: 0.16rem;
        color: #999999;
        span {
          color: #1f86fe;
        }
      }
    }
  }
  .submit-btn {
    margin-top: 0.32rem;
    background: #1f86fe;
    border-radius: 2px;
    padding: 0.12rem 1.84rem;
    font-size: 0.18rem;
    color: #fff;
  }
  .submit-btn:active {
    background: #79b6fe;
  }
  .tips {
    display: flex;
    width: 4.4rem;
    margin-top: 0.24rem;
    justify-content: flex-end;
    align-items: center;
    .tips-item {
      font-size: 0.16rem;
      span {
        color: #1f86fe;
      }
    }
  }
}
/deep/.el-input__inner {
  height: 0.48rem;
}
</style>
