<template>
  <div class="content">
    <div class="header-wrap">
      <img
        :src="url"
        alt=""
        srcset=""
      >
      <div class="line"></div>
      <div class="page-title">验证免费体验</div>
    </div>
    <Login
      v-if="type=='login'"
      @change="typeChange"
    />
    <SubmitForm
      @submit="SubmitFormSubmit"
      @change="typeChange"
      @agree="openagreement"
      v-else
    />
    <AgreementDialog
      :isVisible="agreementDialog"
      @close="agreementDialog=false"
    ></AgreementDialog>
  </div>
</template>

<script>
import SubmitForm from "@/components/submit/SubmitForm.vue";
import Login from "@/components/login/Login.vue"
import { companyapply } from "@/request/api";
import AgreementDialog from "@/components/AgreementDialog/AgreementDialog.vue";
import { manageUrl } from "@/utils/helper";
export default {
  components: {
    SubmitForm,
    Login,
    AgreementDialog
  },
  data() {
    return {
      url: require('@/assets/images/logo.png'),
      type: "",
      agreementDialog: false,
    };
  },
  created() {

    this.type = this.$route.params.type
  },
  methods: {
    SubmitFormSubmit(data) {
      let str = manageUrl()
      this.$axios({
        url: `${str}/api/v1/company/apply`,
        method: 'post',//可以省略 不写默认是get
        data: data
      }).then(res => {
        if (res.code == 0) {
          this.$message.success('提交成功');
          setTimeout(() => {
            this.$router.replace('/')
          }, 1000);
        }

      })
    },
    typeChange(type) {
      this.type = type
    },
    openagreement() {
      this.agreementDialog = true
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  .header-wrap {
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);
    padding: 0rem 5.48rem;
    height: 0.72rem;
    display: flex;
    align-items: center;
    img {
      width: 1.1rem;
      height: 0.36rem;
    }
    .line {
      width: 0.01rem;
      height: 0.32rem;
      background-color: #cccccc;
      margin: 0rem 0.32rem;
    }
    .page-title {
      font-size: 0.18rem;
      color: #999999;
    }
  }
}
</style>
