<template>
  <div class="content">
    <el-form
      ref="ruleForm"
      :model="form"
      :rules="rules"
    >
      <el-form-item
        label=""
        prop="phone"
      >
        <el-input
          v-model="form.phone"
          placeholder="手机号"
        ></el-input>
      </el-form-item>
      <div class="input-wrap">
        <el-form-item
          label=""
          prop="code"
        >
          <el-input
            v-model="form.code"
            placeholder="验证码"
          ></el-input>
        </el-form-item>
        <el-button
          type="primary"
          plain
          :disabled="codedisabled"
          @click="sendsms"
        >{{yzcodetext}}</el-button>
      </div>
    </el-form>
    <div
      class="submit-btn"
      @click="submit('ruleForm')"
    >
      立即提交
    </div>
  </div>
</template>

<script>
import { smscode } from "@/request/api";
export default {
  data() {
    return {
      form: {},
      yzcodetext: "获取验证码",
      codeTime: 0,
      codedisabled: false,
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
      }
    };
  },
  methods: {
    timer() {
      if (this.codeTime > 0) {
        this.codeTime--;
        this.yzcodetext = this.codeTime + "s后";
        setTimeout(this.timer, 1000);
      } else {
        this.codeTime = 0;
        this.yzcodetext = "获取验证码";
        this.codedisabled = false;
      }
    },
    sendsms() {
      if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.form.phone)) {
        this.$message.error("请输入正确的手机号")
        return false;
      } else {
        this.codeTime = 60;
        this.codedisabled = true;
        let query = {
          phone: this.form.phone,
        };
        smscode(query).then((res) => {
          this.timer();
        });
      }
    },
    //提交
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('submit', this.form)
        } else {

          return false;
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>
.content {
  margin-top: 0.32rem;
  width: 4.4rem;
}
/deep/.el-input__inner {
  border-radius: 2px;
  width: 4.4rem;
  height: 0.48rem;
}
.submit-btn {
  margin-top: 0.32rem;
  background: #1f86fe;
  border-radius: 2px;
  padding: 0.12rem 1.84rem;
  font-size: 0.18rem;
  color: #fff;
}
.submit-btn:active {
  background: #79b6fe;
}
.input-wrap {
  display: flex;
  justify-content: space-between;

  /deep/.el-input__inner {
    border-radius: 2px;
    width: 2.74rem;
    height: 0.48rem;
  }
  .el-button {
    width: 1.12rem;
    height: 0.48rem;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    font-size: 0.16rem;
  }
}
</style>
