<template>
  <div class="content">
    <div class="tabs">
      <div
        class="tabs-item"
        :class="{'active-item':tabsIndex==0}"
        @click="changeTabs(0)"
      >账号登陆</div>
      <div class="line"></div>
      <div
        class="tabs-item"
        :class="{'active-item':tabsIndex==1}"
        @click="changeTabs(1)"
      >手机登录</div>
    </div>
    <PasswordLogin
      v-if="tabsIndex==0"
      @submit="PasswordLoginSubmit"
    />
    <PhoneLogin
      @submit="PhoneLoginSubmit"
      v-else
    />
    <div class="tips">
      <div
        class="tips-item"
        @click="handleChangeType"
      >
        已有帐号？<span>立即注册</span>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordLogin from "./PasswordLogin.vue";
import PhoneLogin from "./PhoneLogin.vue";
import { userlogin, userloginsms } from "@/request/api";
import { setStorage, getStorage } from "@/utils/helper";
export default {
  components: {
    PasswordLogin,
    PhoneLogin
  },
  data() {
    return {
      tabsIndex: 0,
    };
  },
  methods: {
    changeTabs(e) {
      this.tabsIndex = e
    },
    PasswordLoginSubmit(data) {
      userlogin(data).then((res) => {
        this.setUser(res.data)
      });
    },
    PhoneLoginSubmit(data) {
      userloginsms(data).then((res) => {
        this.setUser(res.data)
      });
    },
    setUser(res) {
      setStorage("admin_user", res);
      setStorage("admin_token", res.token);
      this.$message.success('提交成功');
      setTimeout(() => {
        this.$router.replace('/')
      }, 1000);

    },
    handleChangeType() {
      this.$emit('change', 'register')
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  margin-top: 0.56rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .tabs {
    display: flex;
    align-items: center;
    .line {
      width: 0.02rem;
      height: 0.16rem;
      background-color: #979797;
      margin: 0rem 0.32rem;
    }
    .tabs-item {
      font-size: 0.24rem;
      cursor: pointer;
      transition: 0.2s;
    }
    .active-item {
      transition: 0.2s;
      color: #1f86fe;
    }
  }
  .tips {
    display: flex;
    width: 4.4rem;
    margin-top: 0.24rem;
    justify-content: flex-end;
    align-items: center;
    .tips-item {
      font-size: 0.16rem;
      span {
        color: #1f86fe;
      }
    }
  }
}
</style>
