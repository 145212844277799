<template>
  <div class="content">
    <el-form
      ref="ruleForm"
      :model="form"
      :rules="rules"
    >
      <el-form-item
        label=""
        prop="phone"
      >
        <el-input
          v-model="form.phone"
          placeholder="手机号"
        ></el-input>
      </el-form-item>
      <el-form-item
        label=""
        prop="password"
      >
        <el-input
          type="password"
          v-model="form.password"
          placeholder="密码"
          show-password
        ></el-input>
      </el-form-item>
    </el-form>
    <div
      class="submit-btn"
      @click="submit('ruleForm')"
    >
      立即提交
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      form: {},
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
      }
    };
  },
  methods: {
    //提交
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('submit', this.form)
        } else {

          return false;
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>
.content {
  margin-top: 0.32rem;
  width: 4.4rem;
}
/deep/.el-input__inner {
  border-radius: 2px;
  width: 4.4rem;
  height: 0.48rem;
}
.submit-btn {
  margin-top: 0.32rem;
  background: #1f86fe;
  border-radius: 2px;
  padding: 0.12rem 1.84rem;
  font-size: 0.18rem;
  color: #fff;
}
.submit-btn:active {
  background: #79b6fe;
}
</style>
